import { useContext, useEffect } from "react";
import cxs from "cxs";
import { PerksContext } from "../../context/PerksContext";
import { PerksScreens } from "../../helpers/constants";
import { isFromMobileWebView, isNativeHeader } from "../../services/header-footer";
import { ContentContext } from "../../context/ContentContext";
import "./index.scss";
import { AppWindow } from "../../helpers/AppWindow";

type PerksNavigationProps = {
  title: string;
  returnToScreen: string;
  customBackgroundColor?: string;
  children?: React.ReactNode;
  webTitle?: string;
};

const MPPerksNavigation = (props: PerksNavigationProps) => {
  const { title, returnToScreen, customBackgroundColor, children, webTitle } = props;
  const { perksState, perksDispatch } = useContext(PerksContext);

  const { contentState } = useContext(ContentContext);

  const { selectedPerkBenefits, activeScreen } = perksState;

  const { backIcon, backIconCategory, backIconColor, backgroundColor, titleColor } =
    contentState?.Content[3]?.perksLanding?.topNav || {};

  const styles = {
    navContainer: cxs({
      paddingTop: isFromMobileWebView() ? `4rem` : `2rem`,
      backgroundColor: customBackgroundColor ? customBackgroundColor : `var(--${backgroundColor}, #03314F)`,
    }),
    backButton: cxs({
      color: `var(--${backIconColor}, #FFFFFF)`,
    }),
    title: cxs({
      color: `var(--${titleColor}, var(--${titleColor}, #FFFFFF))`,
    }),
  };

  const appWindow = window as AppWindow;

  const setToolbarVisibility = (visible: boolean) => {
    if (appWindow.AppInterface && appWindow.AppInterface.setToolbarVisibility) {
      appWindow.AppInterface.setToolbarVisibility(visible);
    }
    if (appWindow.webkit?.messageHandlers?.setToolbarVisibility) {
      appWindow.webkit.messageHandlers.setToolbarVisibility.postMessage(visible);
    }
  };

  useEffect(() => {
    setToolbarVisibility(true);
    if (appWindow.AppInterface && typeof appWindow.AppInterface.setNavigationTitle === "function") {
      appWindow.AppInterface.setNavigationTitle(title);
    }

    setNavigationTitleForIOS();
  }, [title]);

  useEffect(() => {
    const hexColorRegex = /#([0-9A-Fa-f]{6})/;
    const match = customBackgroundColor?.match(hexColorRegex);
    const color = match ? match[0] : "#03314F";
    if (appWindow.AppInterface && typeof appWindow.AppInterface.setColor === "function") {
      appWindow.AppInterface.setColor(color);
    }

    if (appWindow.webkit?.messageHandlers?.setColor) {
      appWindow.webkit.messageHandlers.setColor.postMessage(color);
    }
  }, [customBackgroundColor]);

  const callAppInterface = (shouldPop: boolean) => {
    const appWindow = window as AppWindow;
    if (appWindow.AppInterface && typeof appWindow.AppInterface.popBackStack === "function") {
      appWindow.AppInterface.popBackStack(shouldPop);
    }
  };

  const handleBack = () => {
    if (perksState.activeScreen?.screenName === PerksScreens.Hub) {
      callAppInterface(true);
      handleBackForIOS();
      window.history.back();
    } else if (
      perksState.activeScreen?.screenName === PerksScreens.TermsAndCondition &&
      perksState.activeScreen?.previousScreen === PerksScreens.PerksSelection
    ) {
      perksDispatch({
        type: "SET_ACTIVE_SCREEN",
        value: { screenName: PerksScreens.PerksSelection },
      });
    } else if (perksState.activeScreen?.screenName === PerksScreens.PerksSelectionError) {
      const perks = selectedPerkBenefits.filter((perk) => perk.isSuccessfullyEnrolled === false);
      perksDispatch({
        type: "SET_SELECTED_PERK_BENEFITS",
        value: perks,
      });
      if (perks) {
        perksDispatch({
          type: "SET_ACTIVE_SCREEN",
          value: { screenName: PerksScreens.PerksSelectionConfirmation },
        });
      }
    } else {
      perksDispatch({
        type: "SET_ACTIVE_SCREEN",
        value: { screenName: returnToScreen },
      });
    }
  };

  const handleBackForIOS = () => {
    if (appWindow.webkit && appWindow.webkit.messageHandlers && appWindow.webkit.messageHandlers.handleBack) {
      appWindow.webkit.messageHandlers.handleBack.postMessage(null);
    }
  };

  const setNavigationTitleForIOS = () => {
    if (appWindow.webkit?.messageHandlers?.setNavigationTitle) {
      appWindow.webkit.messageHandlers.setNavigationTitle.postMessage(title);
    }
  };

  (appWindow as any).callHandleBack = handleBack;
  (appWindow as any).callNavTitleUpdate = setNavigationTitleForIOS;
  (appWindow as any).callShowToolBar = setToolbarVisibility(true);

  return (
    <>
      {isNativeHeader() ? (
        <></>
      ) : isFromMobileWebView() ? (
        <div className={`perksNavigation__container ${styles.navContainer}`}>
          <button className={`${styles.backButton}`} onClick={handleBack}>
            <auro-icon
              name={backIcon ?? "chevron-left"}
              category={backIconCategory ?? "interface"}
              customSize
              customColor
            />
          </button>
          <div className="perksNavigation__title">
            <h1 className={styles.title}>{title}</h1>
          </div>
        </div>
      ) : activeScreen?.screenName === PerksScreens.Hub || activeScreen?.screenName === PerksScreens.PerksLadder ? (
        <div className="webNavigation__container">
          <div>
            {!isFromMobileWebView() && <auro-nav>{children}</auro-nav>}
            <h1>{webTitle}</h1>
          </div>
        </div>
      ) : (
        <div className={`perksNavigation__container ${styles.navContainer}`}>
          <button className={`${styles.backButton}`} onClick={handleBack}>
            <auro-icon
              name={backIcon ?? "chevron-left"}
              category={backIconCategory ?? "interface"}
              customSize
              customColor
            />
          </button>
          <div className="perksNavigation__title">
            <h1 className={styles.title}>{title}</h1>
          </div>
        </div>
      )}
    </>
  );
};

export default MPPerksNavigation;
