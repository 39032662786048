import { useContext, useState, useEffect } from "react";
import PerksTile from "../MPPerksTile";
import { ContentContext } from "../../../context/ContentContext";
import { getIcon } from "../../../helpers/getIcon";
import { IMilestonePerk, IPerkBenefit } from "../../../types/perks";
import Spinner from "../../Spinner";
import { useFeatureDecision } from "../../../context/FeatureContext";

type PerkTileListProps = {
  index: number;
  milestone: IMilestonePerk;
  selectedPerks: IPerkBenefit[];
  handleTileSelect: (id: number, index: number) => void;
  testid?: string;
};

const PerkTileList = (props: PerkTileListProps) => {

  const { isPerkEnabled } = useFeatureDecision();

  const [perkSelections, setPerkSelections] = useState<IPerkBenefit[]>([]);

  useEffect(() => {
    let newlst: IPerkBenefit[] = [];
    newlst = props.milestone.perkBenefitsLst.filter(prop => isPerkEnabled(prop.perkName));
    setPerkSelections(newlst);
  }, [props.milestone.perkBenefitsLst.length > 0]);

  const { contentState } = useContext(ContentContext);

  const { index, milestone, selectedPerks, handleTileSelect, testid } = props;

  const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

  const { title, firstMultipleTitle, secondMultipleTitle } = contentState?.Content[1]?.perkSelection || {};

  const contentPerks: any = contentState?.Content[4]?.perks || {};

  const selectedPerk = selectedPerks[index];

  const getPerk = (perkName: string) => {
    const perk = contentState?.Content[4]?.perks?.find((perk) => perk.perkName === perkName);

    if (perk) {
      return perk;
    }
  };

  const getTitle = () => {
    if (milestone.numberOfPerksSelectable === 1) {
      return title;
    } else {
      if (index === 0) {
        return firstMultipleTitle ? firstMultipleTitle : "Select your first perk";
      } else {
        return secondMultipleTitle ? secondMultipleTitle : "Select your second perk";
      }
    }
  };

  const getSubtitle = (perksId: number, perkContentLst: any) => {
    var perkItem = perkContentLst?.find((item: any) => item.id === perksId);
    return perkItem?.perkSelectionTileSubtitle;
  };

  return (
    <div key={index} data-testid={testid}>
      <h2 className={`perksSelectionPage__title`}>{getTitle()}</h2>
      <div className="perksSelectionPage__tiles">
        {perkSelections.length > 0 ? (
          perkSelections?.map((perk, key) => {
            const icon = getIcon(perk.perkBenefitId, list, defaultCategory, defaultIconName);
            const title = getPerk(perk.perkName)?.perkSelectionTileTitle;
            return (
              <PerksTile
                id={perk.perkBenefitId}
                key={perk.perkBenefitId}
                selected={selectedPerk?.perkBenefitId === perk.perkBenefitId}
                description={title as string}
                perkSelectionTileSubtitle={getSubtitle(perk.perkBenefitId, contentPerks)}
                icon={icon.name}
                category={icon.category}
                handleClick={() => handleTileSelect(perk.perkBenefitId, index)}
                variant="outline"
                isButton={true}
                testId={"perk-tile-" + index + "-" + key}
                selectedPerks={selectedPerks}
              />
            );
          })) : (
          <Spinner />
        )}
      </div>
    </div>
  );
};

export default PerkTileList;