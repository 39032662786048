import "./styles.scss";
import { useContext } from "react";
import cxs from "cxs";
import RoundedButton from "../../RoundedButton/RoundedButton";
import Nav from "../../MPPerksNavigation";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { ContentContext } from "../../../context/ContentContext";
import { PerksScreens } from "../../../helpers/constants";
import { replacePlaceholderName } from "../../../helpers/replacePlaceholderName";
import { IGetPerksRequest } from "../../../types/perks";
import UserAccountContext from "../../../context/UserAccountContext";
import fetchPerksData from "../../../helpers/fetchPerksData";

const MPPerksSelectionError = () => {
  const { accountState } = useContext(UserAccountContext);
  const { contentState } = useContext(ContentContext);
  const { perksState, perksDispatch } = useContext(PerksContext);
  const { MileagePlanNumber, asoaMiles } = accountState.MileagePlanDetails;
  const { Guid, Value } = accountState.Token;
  const { selectedPerkBenefits } = perksState;

  const {
    backgroundColor,
    title,
    subTitle,
    retryButtonBackgroundColor,
    retryButtonTitle,
    retryButtonTextColor,
    backHubButtonBorderColor,
    backHubButtonTitle,
    successPerkTitle,
    failedPerkTitle,
  } = contentState?.Content[7]?.errorPage || {};

  const contentPerks: any = contentState?.Content[4]?.perks || {};

  const styles = {
    container: cxs({
      background: `var(--${backgroundColor}, #fff)`,
    }),
    retryBtn: cxs({
      backgroundColor: `var(--${retryButtonBackgroundColor}, #03314F)`,
      color: `var(--${retryButtonTextColor}, #FFFFFF)`,
    }),
    backHubBtn: cxs({
      borderColor: `var(--${backHubButtonBorderColor}, #03314F)`,
    }),
  };

  const handleBackToHub = () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.Hub },
    });
  };

  const handleRetry = async () => {
    const perks = selectedPerkBenefits.filter((perk) => perk.isSuccessfullyEnrolled === false);
    const payload: IGetPerksRequest = {
      asoaMiles: asoaMiles,
      guid: Guid,
      mileagePlanNumber: MileagePlanNumber,
    };
    await fetchPerksData(payload, Value, perksDispatch);
    perksDispatch({
      type: ACTIONS.SET_SELECTED_PERK_BENEFITS,
      value: perks,
    });
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.PerksSelection },
    });
  };

  const getPerkMessage = (isSuccessfullyEnrolled: boolean | undefined, perkId: number) => {
    const perk = contentPerks?.find((item: any) => item.id === perkId);
    const messageTemplate = isSuccessfullyEnrolled
      ? successPerkTitle || "{perkDescription} selection was successful."
      : failedPerkTitle || "{perkDescription} selection was not successful.";
    return replacePlaceholderName(messageTemplate, { perkDescription: perk?.perkSelectionTileTitle || "" });
  };

  const displayPerks = selectedPerkBenefits.map((perk, index) => (
    <div
      key={index}
      className="perks-line"
      data-testid="perk-item"
      dangerouslySetInnerHTML={{ __html: getPerkMessage(perk?.isSuccessfullyEnrolled, perk.perkBenefitId) }}
    />
  ));

  return (
    <div className={`perksErrorPage__container ${styles.container}`}>
      <Nav
        title="Confirm Selection"
        returnToScreen={PerksScreens.PerksSelectionConfirmation}
        customBackgroundColor={backgroundColor || "fff"}
      />
      <div className="perksErrorPage__content">
        <h1 className="perksErrorPage__title" data-testid="title">
          {title || "Something went wrong"}
        </h1>
        <div className="perksErrorPage__info">
          <p data-testid="subtitle">{subTitle || "The selection of the perk was not successful."}</p>
          <div className="perksErrorPage__perksList" data-testid="perk-list">
            {displayPerks}
          </div>
        </div>
        <div className="perksErrorPage__buttons">
          <RoundedButton
            className={styles.retryBtn}
            onClick={handleRetry}
            label={retryButtonTitle || "Retry selection"}
            type="button"
            testId="retry-selection-button"
          />
          <RoundedButton
            className={styles.backHubBtn}
            label={backHubButtonTitle || "Return to perks hub"}
            type="button"
            outline
            onClick={handleBackToHub}
            testId="back-to-hub-button"
          />
        </div>
      </div>
    </div>
  );
};

export default MPPerksSelectionError;
