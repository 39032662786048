import { parseMarkdown } from "../../helpers/parseMarkdown";
import DOMPurify from "dompurify";

const MarkdownRenderer = ({
  markdown,
  markdownClassName,
}: {
  markdown: string | TrustedHTML | Node;
  markdownClassName?: string;
}) => {
  const sanitizedHtmlContent = parseMarkdown({
    markdown: DOMPurify.sanitize(markdown as string, { ADD_ATTR: ["target", "onclick"] }),
  });
  return <div className={markdownClassName} dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />;
};

export default MarkdownRenderer;
