import React, { createContext, ReactNode, useEffect, useMemo, useReducer, useState } from "react";
import { initialContentState, ContentStateType } from "../state/InitialState";
import ContentService from "../services/ContentService";
import { appInsights } from "../services/AppInsights";
import { IGetPerksContentRequest } from "../types/perks";

export type ContentContextType = {
  contentState: ContentStateType;
  contentStateDispatch: any;
};

export const ContentContext = createContext<ContentContextType>({
  contentState: initialContentState,
  contentStateDispatch: () => {},
});

const ACTIONS = {
  SET_CONTENT: "SET_CONTENT",
  SET_ERROR: "SET_ERROR",
  SET_LOADING_DATA: "SET_LOADING_DATA",
};

type ActionType =
  | { type: string; value: (typeof initialContentState)["Content"] | null }
  | { type: string; value: (typeof initialContentState)["Error"] | null }
  | { type: string; value: boolean };

const contentReducer = (state: ContentStateType, action: ActionType): ContentStateType => {
  switch (action.type) {
    case ACTIONS.SET_CONTENT:
      return {
        ...state,
        Content: action.value as (typeof initialContentState)["Content"],
      };
    case ACTIONS.SET_ERROR:
      return {
        ...state,
        Error: action.value as (typeof initialContentState)["Error"],
      };
    case ACTIONS.SET_LOADING_DATA:
      return {
        ...state,
        isLoadingMobileContent: action.value as unknown as typeof initialContentState.isLoadingMobileContent,
      };
    default:
      return state;
  }
};

const ContentProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [contentState, contentStateDispatch] = useReducer(contentReducer, initialContentState);
  const contextValue: ContentContextType = useMemo(
    () => ({
      contentState,
      contentStateDispatch,
    }),
    [contentState, contentStateDispatch]
  );
  const initialBlocks = ["perks", "milestones", "perks_landing", "select_perks", "perks_selection", "icons"];
  const additionalBlocks = ["perks_ladder", "error_page", "perks_redemption_page"];

  const getContentData = async (data?: string[], isPreLoadData: boolean = false) => {
    contentStateDispatch({ type: ACTIONS.SET_LOADING_DATA, value: true });

    await ContentService(
      { selectedBlocks: data } as IGetPerksContentRequest,
      isPreLoadData ? "?cachedRequest=preLoadData" : "?cachedRequest=postLoadData"
    )
      .then((res) => {
        appInsights?.trackEvent({
          name: "BFF Service for pulling mobile content service perks API called",
        });
        if (!res) {
          throw new Error("No response from the Content service.");
        }
        let newContent: any[] = [];
        contentState.Content.forEach((block: any) => {
          Object.entries(block).forEach(([key, value]) => {
            const newValue = res.data.find((resData: any) => resData.hasOwnProperty(key));
            if (newValue) {
              newContent.push(newValue);
            } else {
              newContent.push({ [key]: value });
            }
          });
        });
        contentStateDispatch({ type: "SET_CONTENT", value: newContent });
        contentStateDispatch({ type: ACTIONS.SET_LOADING_DATA, value: false });
        setIsFirstLoad(false);
      })
      .catch((ex) => {
        console.error(ex);
        appInsights.trackException({ exception: ex });
        contentStateDispatch({
          type: "SET_ERROR",
          value: new Error("Unable to retrieve your Content data.") as any,
        });
      });
  };

  useEffect(() => {
    getContentData(initialBlocks, true);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      getContentData(additionalBlocks, false);
    }
  }, [isFirstLoad]);

  return <ContentContext.Provider value={contextValue}>{children}</ContentContext.Provider>;
};

export default ContentProvider;
