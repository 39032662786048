import "./styles.scss";
import { useContext } from "react";
import cxs from "cxs";
import RoundedButton from "../../RoundedButton/RoundedButton";
import Nav from "../../MPPerksNavigation";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import { ContentContext } from "../../../context/ContentContext";
import { PerksScreens } from "../../../helpers/constants";
import { replacePlaceholderName } from "../../../helpers/replacePlaceholderName";
import { IGetPerksRequest } from "../../../types/perks";
import UserAccountContext from "../../../context/UserAccountContext";
import fetchPerksData from "../../../helpers/fetchPerksData";
import ContentViewer from "../../ContentViewer";

const MPPerksRedemptionError = () => {
    const { contentState } = useContext(ContentContext);
    const { perksState, perksDispatch } = useContext(PerksContext);
    
    const {
        background,
        buttonText,
        buttonTextColor,
        buttonBackgroundColor,
    }  = contentState?.Content[8]?.perksRedemptionPage || {};

    const {
        backgroundColor,
        retryButtonBackgroundColor,
        retryButtonTextColor,
        backHubButtonBorderColor
    } = contentState?.Content[7]?.errorPage || {};
    
    const currentBenefit = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption!.perkBenefitId);
    const perksArray = contentState?.Content[8]?.perksRedemptionPage?.perks;
    const contentFromContentStack  = perksArray?.find(perk => perk.perkId === currentBenefit?.perkBenefitId.toString());
    
    const screen = contentFromContentStack?.redemptionFailurePage;

    const styles = {
        container: cxs({
            background: `var(--${backgroundColor}, #FBFFF6)`,
        }),
        retryBtn: cxs({
            backgroundColor: `var(--${retryButtonBackgroundColor}, #03314F)`,
            color: `var(--${retryButtonTextColor}, #FFFFFF)`,
        }),
        backHubBtn: cxs({
            borderColor: `var(--${backHubButtonBorderColor}, #03314F)`,
        }),
    };

    const handleBackToRedemption = () => {
        perksDispatch({
            type: ACTIONS.SET_ACTIVE_SCREEN,
            value: { screenName: PerksScreens.PerksRedemptionSelection },
        });
    };
    
    const pageTitle = (contentFromContentStack?.navTitle?.length || 0) > 0? contentFromContentStack?.navTitle : currentBenefit?.perkDescription;
    
    return (
        <div className={`perksErrorPage__container ${styles.container}`}>
            <Nav
                title={currentBenefit?.perkDescription || pageTitle || "Back to Perks"}
                returnToScreen={PerksScreens.Hub}
                customBackgroundColor={`var(--${background}, #03314F)`}
            />
            <div className="perksErrorPage__content">
                <h1 className="perksErrorPage__title" data-testid="title">
                    {screen?.failureInfoTitle|| "Something went wrong"}
                </h1>
                <div className="perksErrorPage__info">
                    <p data-testid="subtitle">
                        <ContentViewer content={screen?.failureInfoRichText ?? "The Redemption of the perk was not successful."}/>
                    </p>
                </div>
                <div className="perksErrorPage__buttons">
                    <RoundedButton
                        className={styles.retryBtn}
                        onClick={handleBackToRedemption}
                        label={screen?.failureBackButton || "Retry redemption"}
                        type="button"
                        testId="retry-selection-button"
                    />
                </div>
            </div>
        </div>
    );
};

export default MPPerksRedemptionError;