import { createContext, useContext, useState, useEffect } from 'react';
import { OptimizelyProvider, createInstance, setLogLevel } from '@optimizely/react-sdk';

const OptimizelyContext = createContext<any>({
  optimizelyClient: null,
  forceUpdateOptimizeClient: () => { },
});

export const useOptimizelyClient = () => {
  return useContext(OptimizelyContext);
};

const OptimizelyContextProvider = ({ children, userId, mileagePlanNumber }: any) => {
  const [optimizelyClient, setOptimizelyClient] = useState<any>(null);

  useEffect(() => {
    if (userId === "" || mileagePlanNumber === "") {
      return;
    }
    const client = createInstance({
      sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
    });
    client.setUser({
      id: userId,
      attributes: {
        mileagePlanNumber: mileagePlanNumber
      }
    });
    setLogLevel('debug');
    setOptimizelyClient(client);
  }, [userId, mileagePlanNumber]);

  const forceUpdate = () => {
    const client = createInstance({
      sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
    });
    client.setUser({
      id: userId,
      attributes: {
        mileagePlanNumber: mileagePlanNumber
      }
    });
    setLogLevel('debug');
    setOptimizelyClient(client);
  };

  if (!optimizelyClient) {
    return null; // or a loading spinner
  }

  return (
    <OptimizelyContext.Provider value={{
      optimizelyClient: optimizelyClient,
      forceUpdateOptimizeClient: forceUpdate,
    }}>
      <OptimizelyProvider
        optimizely={optimizelyClient}
        userId={userId}
        userAttributes={{
          mileagePlanNumber: mileagePlanNumber,
        }}
      >
        {children}
      </OptimizelyProvider>
    </OptimizelyContext.Provider>
  );
};

export default OptimizelyContextProvider;