import "./App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import "./webcomponents";
import Spinner from "./components/Spinner";
import { reactPlugin } from "./services/AppInsights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import MVPGoldNominationPage from "./components/MVPGoldNominationPage";
import {
  MVPGoldFormPath,
  ThankYouPagePath,
  MPChoiceProductName,
  AvisStatusMatchProductName,
  ABGFormPath,
  MP100KPath,
  ABGConfirmationPath,
  Perks,
} from "./helpers/constants";
import MPThankYouPage from "./components/MPThankYouPage/MPThankYouPage";
import mountHeaderFooter, { isFromMobileWebView } from "./services/header-footer/index";
import UserAccountContext from "./context/UserAccountContext";
import ABGContainer from "./components/ABGContainer";
import ABGConfirmationPage from "./components/ABGConfirmationPage";
import ProtectedRoute from "./components/ProtectedRoute";
import MP100KContainer from "./components/MP100KContainer/MP100KContainer";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import MPPerksPage from "./components/MPPerksPage";
import { FEATURE_FLAGS } from "./context/FeatureContext";
import { PerksProvider } from "./context/PerksContext";
import CustomLoadingSpinner from "./components/Spinner/CustomSpinner";
import { useEffect } from "react";

const App = (props: { data: any }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  let mileagePlanData = props.data;
  let mileagePlanNumber = currentPath === Perks ? props.data.accountState.Token.MileagePlanNumber : mileagePlanData.accountState.MileagePlanDetails.MileagePlanNumber;
  // Product Access Levels:
  // All routes except Avis Status Match T&Cs behind User Auth
  // Avis Status Match --> MVP, Gold, 75k, 100k
  // MP Choice --> 100k & have not made choice yet
  // if (mileagePlanData.accountState.MileagePlanDetails.MileagePlanNumber !== "") {

  useEffect(() => {
    mountHeaderFooter(document.getElementById("alaska-header"), document.getElementById("alaska-footer"));
  }, []);
  
  if (mileagePlanNumber !== "") {
    return (
      <UserAccountContext.Provider value={mileagePlanData}>
        <Routes>
          <Route
            path={MP100KPath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={MPChoiceProductName}>
                <OptimizelyFeature feature={FEATURE_FLAGS.HUNDRED_K_CHOICE}>
                  {(isEnabled, variablesOptimizely100K) =>
                    isEnabled &&
                    (variablesOptimizely100K.mem_num.includes(mileagePlanNumber) ||
                      variablesOptimizely100K.openToAll) ? (
                      <>
                        <AppInsightsContext.Provider value={reactPlugin}>
                          <MP100KContainer />
                        </AppInsightsContext.Provider>
                      </>
                    ) : (
                      `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                    )
                  }
                </OptimizelyFeature>
              </ProtectedRoute>
            }
          />
          <Route
            path={MVPGoldFormPath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={MPChoiceProductName}>
                <OptimizelyFeature feature={FEATURE_FLAGS.HUNDRED_K_CHOICE}>
                  {(isEnabled, variablesOptimizely100K) =>
                    isEnabled &&
                    (variablesOptimizely100K.mem_num.includes(mileagePlanNumber) ||
                      variablesOptimizely100K.openToAll) ? (
                      <>
                        <AppInsightsContext.Provider value={reactPlugin}>
                          <MVPGoldNominationPage />
                        </AppInsightsContext.Provider>
                      </>
                    ) : (
                      `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                    )
                  }
                </OptimizelyFeature>
              </ProtectedRoute>
            }
          />
          <Route
            path={ThankYouPagePath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={MPChoiceProductName}>
                <OptimizelyFeature feature={FEATURE_FLAGS.HUNDRED_K_CHOICE}>
                  {(isEnabled, variablesOptimizely100K) =>
                    isEnabled &&
                    (variablesOptimizely100K.mem_num.includes(mileagePlanNumber) ||
                      variablesOptimizely100K.openToAll) ? (
                      <>
                        <AppInsightsContext.Provider value={reactPlugin}>
                          <MPThankYouPage />
                        </AppInsightsContext.Provider>
                      </>
                    ) : (
                      `We are currently upgrading this 100K Choice experience. Stay tuned for an email notification when it's complete!`
                    )
                  }
                </OptimizelyFeature>
              </ProtectedRoute>
            }
          />
          <Route
            path={ABGFormPath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={AvisStatusMatchProductName}>
                <ABGContainer />
              </ProtectedRoute>
            }
          />
          <Route
            path={Perks}
            element={
              <OptimizelyFeature feature={FEATURE_FLAGS.PERKS}>
                {(isEnabled, variables) => (
                  <ProtectedRoute
                    data={mileagePlanData.accountState}
                    product={Perks}
                    isEnabled={variables.DisplayPerks}
                  >
                    <AppInsightsContext.Provider value={reactPlugin}>
                      <PerksProvider>
                        <MPPerksPage />
                      </PerksProvider>
                    </AppInsightsContext.Provider>
                  </ProtectedRoute>
                )}
              </OptimizelyFeature>
            }
          />
          <Route
            path={ABGConfirmationPath}
            element={
              <ProtectedRoute data={mileagePlanData.accountState} product={AvisStatusMatchProductName}>
                <ABGConfirmationPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </UserAccountContext.Provider>
    );
  } else {
    return isFromMobileWebView() ? <CustomLoadingSpinner /> : <Spinner />;
  }
};

export default App;
