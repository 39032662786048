import "./index.scss";
import MPPerkProgressMilestone from "../../MPPerkProgressMilestone";
import MPSeeAllPerksCta from "../MPSeeAllPerksCta";
import MPPerksDetailsList from "../MPPerksContainer/MPPerksDetailsList";
import { AccountStateType, ContentStateType } from "../../../state/InitialState";
import { useContext, useEffect, useState } from "react";
import { PerksContext } from "../../../context/PerksContext";
import { ContentContext } from "../../../context/ContentContext";
import UserAccountContext from "../../../context/UserAccountContext";
import MPNextPerksMilestone from "../MPNextPerksMilestone";
import { statusTrialHowTierList } from "../../../helpers/constants";

const MPMilestoneContainer = () => {
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);
  const tierMilestoneRequiredMiles = [20000, 40000, 75000, 100000];
  const {
    perksState: { perks },
  } = useContext(PerksContext);

  const [currentMilestone, setCurrentMilestone] = useState<any>();
  const [nextUpcomingMilestone, setNextUpcomingMilestone] = useState<any>();
  const [futureUpcomingMilestone, setFutureUpcomingMilestone] = useState<any>();

  const getCurrentMilestone = (tierName: string) => {
    return perks.allMilestonePerks.find(
      (milestone) => milestone.tierName === accountState.MileagePlanDetails?.TierName
    );
  };

  const getMilestoneCurrent = (milestoneId: number = 0) => {
    let currentMilestone = getCurrentMilestone(accountState.MileagePlanDetails?.TierName);
    let currentMilestoneId = currentMilestone?.milestoneId || 0;
    const milestoneContent = contentState?.Content[5]?.milestones?.find(
      (milestone) => milestone.id === currentMilestoneId
    );
    return {
      ...currentMilestone,
      ...milestoneContent,
    };
  };

  const getMilestoneUpcoming = (milestoneId: number = 0) => {
    let currentMilestone = getCurrentMilestone(accountState.MileagePlanDetails?.TierName);
    let prevTiersMilestones = tierMilestoneRequiredMiles.filter(
      (milestone) => milestone <= (currentMilestone?.milestoneRequiredMiles || 0)
    );
    let nextMilestone = perks.allMilestonePerks
      .filter((milestone) => !prevTiersMilestones.includes(milestone.milestoneRequiredMiles))
      .find((milestone) => milestone.milestoneRequiredMiles > accountState.MileagePlanDetails?.asoaMiles);

    if (!nextMilestone) {
      return null;
    }

    let nextMilestoneId = nextMilestone?.milestoneId || 0;
    const milestoneContent = contentState?.Content[5]?.milestones?.find(
      (milestone) => milestone.id === nextMilestoneId
    );
    return {
      ...nextMilestone,
      ...milestoneContent,
    };
  };

  const getMilestoneFuture = (milestoneId: number = 0) => {
    let currentMilestone = getCurrentMilestone(accountState.MileagePlanDetails?.TierName);
    let prevTiersMilestones = tierMilestoneRequiredMiles.filter(
      (milestone) => milestone <= (currentMilestone?.milestoneRequiredMiles || 0)
    );
    let futureMilestone = perks.allMilestonePerks
      .filter((milestone) => !prevTiersMilestones.includes(milestone.milestoneRequiredMiles))
      .filter((milestone) => milestone.milestoneRequiredMiles > accountState.MileagePlanDetails?.asoaMiles)[1];

    if (!futureMilestone) {
      return null;
    }

    let futureMilestoneId = futureMilestone?.milestoneId || 0;
    const milestoneContent = contentState?.Content[5]?.milestones?.find(
      (milestone) => milestone.id === futureMilestoneId
    );
    return {
      ...futureMilestone,
      ...milestoneContent,
    };
  };

  useEffect(() => {
    setCurrentMilestone(getMilestoneCurrent());
    setNextUpcomingMilestone(getMilestoneUpcoming());
    setFutureUpcomingMilestone(getMilestoneFuture());
  }, [contentState, perks, accountState.MileagePlanDetails]);

  return (
    <>
      <div className="current-status__container">
        <div className="perk-sub-title">Current status</div>
        <div className="current-status-container">
          {currentMilestone && (
            <MPPerksDetailsList
              milestoneId={currentMilestone.milestoneId || 0}
              isCurrentMilestone
              mileStoneStatus={accountState?.MileagePlanDetails?.TierName}
              oneworldTierstatus={accountState?.MileagePlanDetails?.oneWorldTier}
              isStatusHowTier={statusTrialHowTierList.includes(accountState?.MileagePlanDetails?.HowTier) ?? false}
              tierEndDate={accountState?.MileagePlanDetails?.TierEndDate}
              qualifyingMiles={currentMilestone.milestoneRequiredMiles || 0}
              chip={currentMilestone.chips}
              info={currentMilestone.milestoneDetails ?? ""}
              summaryRichText={currentMilestone.summaryRichText ?? ""}
              milestoneHeading={currentMilestone.milestoneHeading ?? ""}
              expandingButton={currentMilestone.expandingButton}
            />
          )}
        </div>
      </div>

      <div className="upcoming-milestones__container">
        <div className="upcoming-sub-title">Coming up</div>
        <div className="milestone-container">
          <MPPerkProgressMilestone />
          <MPNextPerksMilestone />
          <div className="section-divider" />
          {nextUpcomingMilestone && (
            <>
              <div className="next-milestone-list">
                <MPPerksDetailsList
                  milestoneId={nextUpcomingMilestone.milestoneId}
                  isCurrentMilestone={false}
                  mileStoneStatus={nextUpcomingMilestone.tierName ?? ""}
                  oneworldTierstatus={nextUpcomingMilestone.oneWorldTier ?? ""}
                  qualifyingMiles={nextUpcomingMilestone.milestoneRequiredMiles}
                  chip={nextUpcomingMilestone.chips}
                  summaryRichText={nextUpcomingMilestone.summaryRichText ?? ""}
                  info={nextUpcomingMilestone.milestoneDetails ?? ""}
                  milestoneHeading={nextUpcomingMilestone.milestoneHeading ?? ""}
                  expandingButton={nextUpcomingMilestone.expandingButton}
                />
              </div>
              <div className="section-divider" />
            </>
          )}

          {futureUpcomingMilestone && (
            <>
              <div className="future-milestone-list">
                <MPPerksDetailsList
                  milestoneId={futureUpcomingMilestone.milestoneId}
                  isCurrentMilestone={false}
                  mileStoneStatus={futureUpcomingMilestone.tierName ?? ""}
                  oneworldTierstatus={futureUpcomingMilestone.oneWorldTier ?? ""}
                  qualifyingMiles={futureUpcomingMilestone.milestoneRequiredMiles ?? ""}
                  chip={futureUpcomingMilestone.chips}
                  summaryRichText={futureUpcomingMilestone.summaryRichText ?? ""}
                  info={futureUpcomingMilestone.milestoneDetails ?? ""}
                  milestoneHeading={futureUpcomingMilestone.milestoneHeading ?? ""}
                  expandingButton={futureUpcomingMilestone.expandingButton}
                />
              </div>
              <div className="section-divider" />
            </>
          )}

          <MPSeeAllPerksCta />
        </div>
      </div>
    </>
  );
};

export default MPMilestoneContainer;
