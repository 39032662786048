import "./index.scss";
import { ContentStateType } from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import { useContext, useEffect } from "react";
import { setStyleProperties } from "../../../helpers/setStyleProperties";
import { PerksScreens } from "../../../helpers/constants";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";

const MPSeeAllPerksCta = () => {
  const { perksDispatch } = useContext(PerksContext);
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);

  const seeAllPerks = contentState?.Content[3]?.perksLanding?.seeAllPerks ?? {
    title: "",
    titleColor: "",
    actionUrl: "",
  };

  const handleClick = () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.PerksLadder },
    });
  };

  useEffect(() => {
    // Set CSS variables dynamically
    setStyleProperties([
      {
        name: "--see-all-perk-cta-color",
        value: seeAllPerks.titleColor || "#03314F",
      },
    ]);
  }, [contentState]);

  return (
    <div onClick={handleClick} className="cta-layout">
      <a className={`see-all-perk-cta`}>{seeAllPerks.title}</a>
      <auro-icon
        class={`icon-chevron-right`}
        category="interface"
        name="chevron-right"
        customSize
        customColor
        data-testid="icon-element"
      ></auro-icon>
    </div>
  );
};

export default MPSeeAllPerksCta;
