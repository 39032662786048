import {getIcon} from "../../../helpers/getIcon";
import PerksTile from "../../MPPerksSelection/MPPerksTile";
import RoundedButton from "../../RoundedButton/RoundedButton";
import {JSX, useContext, useState} from "react";
import UserAccountContext from "../../../context/UserAccountContext";
import {ContentContext} from "../../../context/ContentContext";
import {ACTIONS, PerksContext} from "../../../context/PerksContext";
import cxs from "cxs";
import {IEnrollPerksRequest, IFulfillmentPerkDetails, IPerkBenefit} from "../../../types/perks";
import {PerksFulfillmentService, PerksFulfillmentRequest} from "../../../hooks/PerksFulfillmentService"
import {PerksScreens} from "../../../helpers/constants";
import {ContentStateType} from "../../../state/InitialState";
import "./index.scss";
import {PerkRedemptionScreen, RedemptionFlow} from "../MPPerksRedemption";
import ContentViewer from "../../ContentViewer";
import {AxiosResponse} from "axios";
import {NominationPerks} from "../../../helpers/constants";

const MPPerksRedemptionConfirmation = () => {
    const { accountState } = useContext(UserAccountContext);
    const { contentState } = useContext(ContentContext);
    const { perksState, perksDispatch, getPerksData } = useContext(PerksContext);
    const {perkFulfillmentStates} = perksState;
    const [fulfillmentStates] = useState<IFulfillmentPerkDetails[]>(perkFulfillmentStates);

    const {
        backgroundColor,
        titleColor,
        subtitleColor,
        cancelButtonBackgroundColor,
        confirmButtonBackgroundColor,
    } = contentState?.Content[1]?.perkSelection?.confirmSelection || {};
    
    const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

    const styles = {
        container: cxs({
            background: `var(--${backgroundColor}, #FBFFF6)`,
        }),
        header: cxs({ color: `var(--${titleColor}, #151515)` }),
        subtitle: cxs({ color: `var(--${subtitleColor}, #2e2e2e)` }),
        confirmButton: cxs({
            color: `#fff`,
            backgroundColor: `var(--${confirmButtonBackgroundColor}, #03314f)`,
        }),
        cancelButton: cxs({
            borderColor: `var(--${cancelButtonBackgroundColor}, #ffffff)`,
        }),
    };

    const currentBenefit = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption!.perkBenefitId);


    const perksArray = contentState?.Content[8]?.perksRedemptionPage?.perks;

    const contentFromContentStack  = perksArray?.find(perk => perk.perkId === currentBenefit?.perkBenefitId.toString());

    const handleConfirm = async () => {
        
        const perkFulfillment = fulfillmentStates.find(fulfillment => fulfillment.perkBenefitId ===  perksState.activeRedemption!.perkBenefitId);
        const perk = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption!.perkBenefitId);
        
        console.log("fulfillment", perkFulfillment)
        console.log("perk", perk)
        
        if(!perk || !perkFulfillment || !perkFulfillment.fulfillmentDate) {
            return;
        }
        
        const request : PerksFulfillmentRequest = 
            {
                enrollPerkId: perk.perkBenefitId,
                milestoneId: perk.milestoneId,
                firstName: accountState.MileagePlanDetails.FirstName,
                lastName: accountState.MileagePlanDetails.LastName,
                mpNumber: accountState.MileagePlanDetails.MileagePlanNumber,
                emailAddress: accountState.MileagePlanDetails.Email,
                accountGuid: accountState.Token.Guid,
                fulfillmentDate: new Date(perkFulfillment.fulfillmentDate).toISOString(),
                nominee: {
                    firstName : "fname",
                    lastName : "lname",
                    mpNumber : perksState.activeScreen?.data["MPNumber"],
                    emailAddress : "email"
                }
            };
        
        try {
            const res = await PerksFulfillmentService(request, accountState.Token.Value);
            perksDispatch({
                type: ACTIONS.SET_ACTIVE_SCREEN,
                value: {screenName: PerksScreens.PerksRedemptionSuccess},
            });
            getPerksData();
        }
        catch (e){
            console.log(e);
        }
    };

    const handleCancel = () => {
        perksDispatch({
            type: ACTIONS.SET_ACTIVE_SCREEN,
            value: {screenName: PerksScreens.PerksRedemptionSelection},
        });
    };
   
    const icon = getIcon(perksState.activeRedemption!.perkBenefitId, list, defaultCategory, defaultIconName);
    
    const currentFulfillment = perksState.perkFulfillmentStates.find(perk => perk.perkBenefitId == perksState.activeRedemption?.perkBenefitId);
    const currentPerk = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption?.perkBenefitId);
    
    const redemptionDataMap = perksState.activeScreen?.data;
    const renderRedemptionDetails = () =>
    {
        const formDisplay = contentFromContentStack?.redemptionConfirmationPage?.reviewFields;
        if (!contentFromContentStack || !formDisplay) return;
        
        const startDateString = currentFulfillment!.fulfillmentDate;
        const startDate = startDateString? new Date(startDateString) : null;
        let endDate = new Date(startDate!);
        const currentDate = new Date();
        const cutoffDate = new Date(currentDate.getFullYear(), 9, 1); //october 1, current year
        const isNomination = parseInt(contentFromContentStack.perkId) in NominationPerks;
        
        const display = formDisplay?.map((field) => {
            let value = redemptionDataMap[field.name];
            if (field.name === "startDate")
            {
                value = startDate?.toLocaleDateString();
            }
            if (field.name === "endDate" && !isNomination)
            {
                // Set the time to 12:00 AM (midnight)
                startDate?.setHours(0, 0, 0, 0)
                endDate!.setDate(startDate!.getDate() + 13);
                value = endDate.toLocaleDateString();
            }
            if (field.name === "endDate" && isNomination) 
            {
                if (currentDate < cutoffDate)// => endDate = December 31, currentYear
                {
                  endDate = new Date(currentDate.getFullYear(), 11, 31)  
                }
                else {//Else endDate = December 31, currentYear + 1
                    endDate = new Date(currentDate.getFullYear()+1, 11, 31)
                }
                value = endDate.toLocaleDateString();
            }

            return (
                <div className={`perksConfirmationPage__subtitle ${styles.subtitle}`}>
                    <b>{field.displayName + " "}</b>{value}
                </div>
            );
        });
        return (<div>{display}</div>);
    }
    
    const page = contentFromContentStack?.redemptionConfirmationPage;
    
    const perkList: IPerkBenefit[] = [
        {
            perkBenefitId: currentPerk?.perkBenefitId ?? 0,
            perkDescription: currentPerk?.perkDescription ?? "",
            perkName: currentPerk?.perkName ?? "",
            isEnabled: true
        }]
    
    return (
        <div className={`perksRedemptionConfirmationPage__container ${styles.container}`}>
            <div className="perksRedemptionConfirmationPage__headerText">
                <h2 className={`perksRedemptionConfirmationPage__title ${styles.header}`}>{page?.title}</h2>
                <p className={`perksRedemptionConfirmationPage__subtitle ${styles.subtitle}`}>{page?.subtitle}</p>
            </div>
            <div className="perksRedemptionConfirmationPage__tilesContainer">
                <PerksTile
                    id={currentPerk!.perkBenefitId}
                    key={currentPerk!.perkBenefitId}
                    selected={true}
                    description={currentPerk!.perkDescription}
                    perkSelectionTileSubtitle={""}
                    icon={icon.name}
                    category={icon.category}
                    handleClick={() => {
                    }}
                    variant="outline"
                    isButton={false}
                    selectedPerks={perkList}
                />
                
            </div>
            <div className={"PerksRedemptionConfirmationPage_infoContainer"}>
                <ContentViewer content={contentFromContentStack?.redemptionConfirmationPage.reviewText ?? " "}/>
                {renderRedemptionDetails()}
            </div>
            
            <div className="perksRedemptionConfirmationPage__buttons">
                <RoundedButton
                    className={styles.confirmButton}
                    label={page?.confirmButtonCta ?? "Confirm"}
                    type="button"
                    onClick={handleConfirm}
                />
                <RoundedButton
                    className={styles.cancelButton}
                    label={page?.backButtonCta ?? "Choose a different perk"}
                    type="button"
                    outline
                    onClick={handleCancel}
                />
            </div>
        </div>
    );
}

export default MPPerksRedemptionConfirmation;