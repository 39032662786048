import React, { useContext, useState, useEffect, useRef } from "react";
import "./index.scss";
import MilestoneBadge, { MilestoneBadgeType } from "./MilestoneBadge";
import { formatNumber } from "../../../../helpers/formatNumber";
import { formatDate } from "../../../../helpers/formatDate";
import { ContentStateType } from "../../../../state/InitialState";
import { ContentContext } from "../../../../context/ContentContext";
import { replacePlaceholderName } from "./../../../../helpers/replacePlaceholderName";
import ContentViewer from "../../../ContentViewer";
import cxs from "cxs";

export interface ChipDetailProps {
  textColor: string;
  backgroundColor: string;
  subtractColor: string;
  title: string;
}

export interface ExpandingButtonProps {
  expandedText: string;
  unexpandedText: string;
  textColor: string;
}

export interface FuturePerksDetailProps {
  milestoneId?: string | number;
  info: string;
  chip: ChipDetailProps | undefined;
  summaryRichText: string;
  summary?: string;
  mileStoneStatus?: string;
  oneworldTierstatus?: string;
  qualifyingMiles?: number | string;
  isCurrentMilestone?: boolean;
  perksLadderText?: string;
  perksLadderTextShowIf?: boolean;
  milestoneHeading?: string;
  expandingButton?: ExpandingButtonProps | undefined;
  isStatusHowTier?: boolean;
  tierEndDate?: string;
}

const MPPerksDetailsList: React.FC<FuturePerksDetailProps> = (props) => {
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { eliteQualifyingMiles } =
    contentState?.Content[3]?.perksLanding?.milestoneSummary?.currentMilestoneDetails || {};
  const trialMessage =
    contentState?.Content[3]?.perksLanding?.milestoneSummary?.currentMilestoneDetails?.trialMessage || "";
  const trialHeading =
    contentState?.Content[3]?.perksLanding?.milestoneSummary?.currentMilestoneDetails?.trialHeading || "";
  const [isExpandedCScontent, setIsExpandedCScontent] = useState(false);
  const [benefitItems, setBenefitItems] = useState<HTMLElement[]>([]);

  // Refs for the benefit list and content data
  const contentDataRef = useRef<HTMLDivElement | null>(null);
  const benefitItemsRef = useRef<HTMLElement[]>([]); // Ref to track the benefit items

  const styles = {
    expandingButton: cxs({
      color: `var(--${props.expandingButton?.textColor}, #03314F)`,
    }),
  };

  // Initialize benefitItems and handle initial hiding
  useEffect(() => {
    const contentData = contentDataRef.current;

    if (contentData) {
      // Get all benefit items
      const items = Array.from(contentData.querySelectorAll(".benefit-item-container"));
      benefitItemsRef.current = items as HTMLElement[];
      setBenefitItems(benefitItemsRef.current);

      // Hide items after the first 3 initially
      if (!isExpandedCScontent) {
        benefitItemsRef.current.forEach((item, index) => {
          if (index >= 3) {
            (item as HTMLElement).style.display = "none";
          }
        });
      }
    }
  }, [isExpandedCScontent, props.summaryRichText]); // Re-run when `isExpandedCScontent` changes

  const showMore = () => {
    benefitItemsRef.current.forEach((item, index) => {
      if (index >= 3) {
        item.style.display = "block";
      }
    });
    setIsExpandedCScontent(true);
  };

  const showLess = () => {
    benefitItemsRef.current.forEach((item, index) => {
      if (index >= 3) {
        item.style.display = "none";
      }
    });
    setIsExpandedCScontent(false);
  };

  const formattedTradeSymbol = props.summaryRichText?.replace(
    /&reg;|®/g,
    `<span class="trademark-symbol">&reg;</span>`
  ) as string | TrustedHTML;

  return (
    <div className="future-perks-container">
      <div className="milestone-badge-container">
        <MilestoneBadge
          milestoneId={props.milestoneId}
          textColor={props.chip?.textColor ?? ""}
          backgroundColor={props.chip?.backgroundColor ?? ""}
          offsetColor={props.chip?.subtractColor ?? ""}
          title={props.chip?.title ?? ""}
          type={props.chip?.subtractColor === "" ? MilestoneBadgeType.Normal : MilestoneBadgeType.Colored}
        />
        <p className="milestone-status" style={{ visibility: props.perksLadderTextShowIf ? "visible" : "hidden" }}>
          {props?.perksLadderText}
        </p>
      </div>

      {!props.isStatusHowTier ? (
        props.mileStoneStatus && props.oneworldTierstatus ? (
          <div className="current-status-text-container">
            <span className="current-status-tier">
              {props.mileStoneStatus} / one
              <span style={{ fontWeight: 300 }}>world</span>
            </span>
            <span className="trademark-symbol">®</span>{" "}
            <span className={`one-world-${props.oneworldTierstatus}`}>{props.oneworldTierstatus}</span>
          </div>
        ) : (
          props.milestoneHeading && (
            <div className="current-status-text-container">
              <span className="current-status-tier">{props.milestoneHeading}</span>
            </div>
          )
        )
      ) : (
        <div className="current-status-trial-heading">
          {replacePlaceholderName(trialHeading || "", {
            endDate: formatDate(props.tierEndDate ?? "") || "",
          })}
        </div>
      )}

      {!props.isStatusHowTier && (props.qualifyingMiles || props.qualifyingMiles === 0) ? (
        <div className="elite-qualifying-miles">
          {replacePlaceholderName(eliteQualifyingMiles || "", {
            qm:
              props.qualifyingMiles === 0
                ? `Less than ${formatNumber(20000)}`
                : (formatNumber(props.qualifyingMiles) as string),
          })}
        </div>
      ) : (
        <div className="current-status-trial-message">
          {replacePlaceholderName(trialMessage || "", {
            tierName: props.mileStoneStatus || "",
          })}
        </div>
      )}

      <p className={props.isCurrentMilestone ? "current-milestone-description" : "perk-description"}>{props.info}</p>

      <div className="benefit-list">
        <ContentViewer
          ref={contentDataRef}
          markdownClassName="benefit-list"
          content={formattedTradeSymbol || ""}
          isPage={false}
        />

        {benefitItems.length > 3 && props.summaryRichText ? (
          <>
            {isExpandedCScontent && (
              <div className={`show-less ${styles.expandingButton}`} id="seeLessBtn" onClick={showLess}>
                <auro-icon
                  class="icon-chevron-left"
                  customColor
                  customSize
                  test-id="show-less"
                  category="interface"
                  name="chevron-up"
                />
                <div>{props.expandingButton?.expandedText}</div>
              </div>
            )}

            {!isExpandedCScontent && (
              <div className={`show-more ${styles.expandingButton}`} id="seeMoreBtn" onClick={showMore}>
                <auro-icon class="icon-chevron-left" customSize customColor category="interface" name="chevron-down" />
                <div>{props.expandingButton?.unexpandedText}</div>
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MPPerksDetailsList;
