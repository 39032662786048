import {useContext, useEffect, useRef, useState} from "react";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import cxs from "cxs";
import { IFulfillmentPerkDetails, IPastPerk, IPerkBenefit, PerkFulfillmentState } from "../../../types/perks";
import { PerksScreens } from "../../../helpers/constants";
import RoundedButton from "../../RoundedButton/RoundedButton";
import Nav from "../../MPPerksNavigation";
import "./index.scss";
import {validateNominee} from "../../../hooks/NomineeValidationService";
import UserAccountContext from "../../../context/UserAccountContext";
import {Axios, AxiosResponse} from "axios";

export interface RedemptionFlow
{
    background: string,
    buttonBackgroundColor: string,
    buttonTextColor: string,
    buttonText: string,
    perksRedemptionScreens: PerkRedemptionScreen[]
}
export interface PerkRedemptionScreen
{
    perkId: number
    navTitle?: string,
    redemptionCtaPage: RedemptionCtaPage,
    redemptionFailurePage: RedemptionFailurePage,
    redemptionSuccessPage: RedemptionSuccessPage,
    redemptionConfirmationPage: RedemptionConfirmationPage
}

export type RedemptionCtaPage = {
    title?: string,
    subtitleRichText?: string,
    datePickerInfoTop?: string,
    datePickerInfoBottom?: string,
    datePickerHint?: string,
    submitButtonText: string;
    fields?: Field [],
}
export type Field = {
    fieldName: string,
    fieldHint: string,
    validationRegex?: string,
    errorHint?: string,
    fieldInstruction?: string
}

export type RedemptionFailurePage = {
    failureInfoRichText?: string,
    failureBackButton?: string,
}

export type RedemptionSuccessPage = {
    successTitle?: string,
    successSubtitle?: string,
    successBackButton?: string,
}

type RedemptionConfirmationPage = {
    reviewText?: string,
    title?: string,
    iconSubtitle?: string
    subtitle?: string,
    confirmButtonCta?: string,
    backButtonCta?: string,
    reviewFields?: ReviewField[]
}

type ReviewField = {
    displayName: string,
    name: string
    fontWeight?: string,
    order?: number
}

const MPPerksRedemption = () => {
    const { contentState } = useContext(ContentContext);
    const { perksState, perksDispatch } = useContext(PerksContext);
    const {accountState} = useContext(UserAccountContext)
    const [isSelectDisabled, setIsSelectDisabled] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [startDate, setStartDate] = useState('');
    const [dateIsInFuture, setDateIsInFuture] = useState<boolean>(false);
    const {perkFulfillmentStates} = perksState;
    const [fulfillmentStates, setFulfillmentStates] = useState<IFulfillmentPerkDetails[]>(perkFulfillmentStates);
    const dateRef = useRef<JSX.IntrinsicElements["auro-datepicker"]>();
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [nomineeResponse, setNomineeResponse] = useState<AxiosResponse>();
    
    const dictionary: { [key: string]: string} = {
        "MPNumber": "",
        "MPNumberConfirmation" : "",
    };
    
    const [formData, setFormData] = useState(dictionary);
    
   
    interface PasteEvent extends React.ClipboardEvent<HTMLInputElement> {}

    const handlePaste = (event: PasteEvent): void => {
        event.preventDefault();
    };

  
    const currentBenefit = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption!.perkBenefitId);
    
    const perksArray = contentState?.Content[8]?.perksRedemptionPage?.perks;
    
    const contentFromContentStack : any = perksArray?.find(perk => perk.perkId === currentBenefit?.perkBenefitId.toString());
    
    const selectionDate = new Date(currentBenefit!.selectionDate);
    const redeemByDate = new Date(selectionDate.getFullYear() + 1, selectionDate.getMonth(), selectionDate.getDate());


    const handleChange = (e : any) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name]: value
        });
    };
    
    const normalizeDate = (inputDate : Date) =>
    {
        return new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate())
    }
    
    const renderFields = (redemptionScreen: PerkRedemptionScreen | undefined) =>
    {
        if (!redemptionScreen || redemptionScreen.redemptionCtaPage.fields?.length == 0) return;
        
        const fieldsDisplay = redemptionScreen.redemptionCtaPage.fields? 
            redemptionScreen.redemptionCtaPage.fields.map((field, index) => {
                
                const pattern = /\{([^{}]*)\}/g;
                const matches = field.validationRegex?.match(pattern);
                const match = matches? matches[0].slice(1,-1) : "";
                
                let validation = field.validationRegex;

                if (match.length > 0 && formData[match])
                {
                    validation =  formData[match];
                }
      
                return (
                    <div>
                        <p>{field.fieldInstruction}</p>
                        <auro-input
                            bordered
                            inset
                            required
                            rounded
                            autocomplete={"off"}
                            data-testid={field.fieldHint + index}
                            id={"fields:" + field.fieldHint}
                            name={field.fieldName}
                            onInput={handleChange}
                            onPaste={handlePaste}
                            value={""}
                            pattern={validation}
                            validateOnInput
                            setCustomValidityValueMissing={field.fieldHint + " is required."}
                            setCustomValidityBadInput={field.errorHint}
                        >
                            <span slot="label">{field.fieldHint}</span>
                        </auro-input>
                    </div>
                )
            }) :
            <></>;

        return (<div>{fieldsDisplay}</div>);
    }

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        setStartDate(formattedDate);
        setSelectedDate(formattedDate);
    }, []);

    useEffect(() => {
        perksDispatch({
            type: ACTIONS.SET_REDEMPTION_STATE,
            value: fulfillmentStates,
        });

        const stateAdded = fulfillmentStates.find(state =>
            state.perkBenefitId == perksState.activeRedemption?.perkBenefitId
            && state.fulfillmentState === PerkFulfillmentState.FulfilledActive);
        
        const isValid = stateAdded && formIsValid;
        
        if (isValid && contentFromContentStack.redemptionCtaPage.fields?.length == 0)
        {
            setFormIsValid(true);
            perksDispatch({
                type: ACTIONS.SET_ACTIVE_SCREEN,
                value: {screenName: PerksScreens.PerksRedemptionConfirmation, data: formData},
            });
            return;
        }
        
        if (isValid)
        {
            const validateFormData = async () =>
            {
                try{
                    const result = await validateNominee(formData["MPNumber"],accountState.Token.Value, accountState.Token.Guid);
                    
                    setNomineeResponse(result);
                    setFormIsValid(true);
                    perksDispatch({
                        type: ACTIONS.SET_ACTIVE_SCREEN,
                        value: {screenName: PerksScreens.PerksRedemptionConfirmation, data: formData},
                    });
                }
                catch (e)
                {
                    setFormIsValid(false);
                    perksDispatch({
                        type: ACTIONS.SET_ACTIVE_SCREEN,
                        value: {screenName: PerksScreens.PerksRedemptionError},
                    });
                }
            }
            validateFormData();
        }
    }, [fulfillmentStates])
    
    useEffect(() => {
        //no data entered for MPNumber confirmation and mpnumber fields might be equal but are blank 
        
        if (contentFromContentStack?.redemptionCtaPage.fields)
        {
            if (formData["MPNumber"].length === 0 && contentFromContentStack?.redemptionCtaPage.fields?.length)
            {
                setIsSelectDisabled(true);
                setFormIsValid(false);
                return;
            }
            //fields are being sent in from the content stack but are not filled out
            //mpnumber and mpnumber confirmation do not match
            if(contentFromContentStack?.redemptionCtaPage.fields &&
                formData["MPNumber"].length > 0 &&
                formData["MPNumber"] !==  formData["MPNumberConfirmation"])
            {
                setIsSelectDisabled(true);
                setFormIsValid(false);
                return;
            }
        }
     
        
        //there is no date picker and the form has been validated by clearing
        //the previous conditions
        if (!contentFromContentStack?.redemptionCtaPage.datePickerHint)
        {
            setIsSelectDisabled(false);
            setFormIsValid(true);
            return;
        }
        //datepicker exists on page and date must be valid
        
        const normalizedCurrentDate = normalizeDate(new Date());
        const normalizedSelectedDateObj = normalizeDate(new Date(selectedDate));
        
        if ( normalizedSelectedDateObj <= redeemByDate && (dateIsInFuture || normalizedSelectedDateObj.getDate() == normalizedCurrentDate.getDate()))
        {
            if(dateRef.current && dateRef.current.hasAttribute("error"))
            {
                dateRef.current.removeAttribute("error");
            }
            setFormIsValid(true);
            setIsSelectDisabled(false);
        }

        else {
            setIsSelectDisabled(true);
            setFormIsValid(false);
            if(dateRef.current)
            {
                dateRef.current.error = "Please enter a start date between " + new Date().toLocaleDateString() + " and " + redeemByDate.toLocaleDateString() + "."
            }
            return;
        }
    }, [selectedDate, formData]);

    const {
        background,
        buttonBackgroundColor,
        buttonText,
        buttonTextColor,
    } = contentState?.Content[8]?.perksRedemptionPage || {};;

    const {
        ctaButtonTitle,
        ctaDisabledBackgroundColor,
        ctaDisabledColor,
        ctaEnabledBackgroundColor,
        ctaEnabledColor,
    } = contentState?.Content[1]?.perkSelection || {};
    
    const { list, default: defaultIconName, defaultCategory } = contentState?.Content[2]?.icons || {};

    const styles = {
        container: cxs({
            background: `var(--${background},#f2fcfa)`,
        }),
        header: cxs({ color: `var(--${background}, #151515)` }),
        subtitle: cxs({ color: `var(--${background}, #2e2e2e)` }),
        selectButton: cxs({
            borderColor: `var(--${buttonTextColor}, #ffffff)`,
        }),
        selectBtn: cxs({
            backgroundColor: isSelectDisabled
                ? `var(--${ctaDisabledBackgroundColor}, #d4d4d4)`
                : `var(--${buttonBackgroundColor}, #03314f)`,
            color: isSelectDisabled ? `var(--${ctaDisabledColor}, #ffffff)` : `var(--${ctaEnabledColor}, #ffffff)`,
        }),
    };
    
    const handleDateChange = (inputEvent : string) => {
        const startDate : Date = new Date(inputEvent);
        const currentDate = new Date();
        const normalizedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const normalizedCurrentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        
        const formattedDate = startDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        
        setSelectedDate(formattedDate);
        setDateIsInFuture(normalizedStartDate >= normalizedCurrentDate);
    };

    const handleSelectButton = () => {
        const updatedFulfillment = fulfillmentStates.map(state => {
              if (state.perkBenefitId == perksState.activeRedemption!.perkBenefitId)
                  return {...state, 
                      fulfillmentState: PerkFulfillmentState.FulfilledActive,
                      fulfillmentDate: selectedDate
              }
            return state
        });

        setFulfillmentStates(updatedFulfillment);
        if (!formData["MPNumber"])//dont need to validate nominee on certain flows
        {
            setFormIsValid(true);
            return;
        }
    };
    
    const handleTextReplacementsDisplay = (template: string) => {
        if (!template) return "";
        const startDateString = new Date();
        const startDate = startDateString? new Date(startDateString) : null;

        const currentDate = new Date();
        const cutoffDate = new Date(currentDate.getFullYear(), 9, 1); //october 1, current year
        let endDate;
        if (currentDate < cutoffDate)// => endDate = December 31, currentYear
        {
            endDate = new Date(currentDate.getFullYear(), 11, 31)
        }
        else {//Else endDate = December 31, currentYear + 1
            endDate = new Date(currentDate.getFullYear()+1, 11, 31)
        }
        
        type Replacement = {
            [key: string] : string;
        }
        const replacements : Replacement = {
            "startDate" : startDate!.toLocaleDateString(),
            "endDate" : endDate.toLocaleDateString()
        };
        
        
        return template.replace(/\{([^{}]+)\}/g, (match : string, key) => {
            return replacements[key] || match;
        });
    }
    
    const page = contentFromContentStack?.redemptionCtaPage;
    const title = contentFromContentStack?.navTitle.length > 0? contentFromContentStack?.navTitle : 
        currentBenefit?.perkDescription;

    return (
        <div className={`perksSelectionPage ${styles.container}`}>
            <Nav
                title={title ?? " " }
                returnToScreen={PerksScreens.Hub}
            />
            <div className="perksConfirmationPage__headerText">
                <p className="perk-page-selection-introduction">{page?.title}</p>
                {page?.subtitleRichText}
            </div>
            <div className="container">
                {renderFields(contentFromContentStack)}
                {page?.datePickerHint?
                    <>
                    {page?.datePickerInfoTop}
                    <auro-datepicker
                        required={true}
                        ref={dateRef}
                        value={selectedDate}
                        calendarStartDate={startDate}
                        calendarFocusDate={startDate}
                        onInput={(e: { target: { value: string; }; }) => handleDateChange(e.target.value)}
                    >
                        <span slot="fromLabel">{page?.datePickerHint}</span>
                        <span slot="mobileDateLabel">{page?.datePickerHint}</span>
                    </auro-datepicker>

                    <p> {handleTextReplacementsDisplay(page?.datePickerInfoBottom ?? "")}</p>
                    </>
                        : <>
                            <p>{handleTextReplacementsDisplay(page?.datePickerInfoTop ?? "")}</p>
                            <p>{handleTextReplacementsDisplay(page?.datePickerInfoBottom ?? "")}</p>
                        </>}
                    </div>
                    <div className="container">
                <RoundedButton
                    className={`${styles.selectBtn}`}
                    label={page?.SubmitButtonText ?? buttonText ?? "Select"}
                    {...(isSelectDisabled ? { disabled: true } : {})}
                    onClick={handleSelectButton}
                    type="button"
                    testId="select-button"
                />
            </div>
        </div>

    );
};

export default MPPerksRedemption;