import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { formatNumber } from "../../helpers/formatNumber";
import { AccountStateType, ContentStateType } from "../../state/InitialState";
import { ContentContext } from "../../context/ContentContext";
import { replacePlaceholderName } from "../../helpers/replacePlaceholderName";
import { PerksContext } from "../../context/PerksContext";
import UserAccountContext from "../../context/UserAccountContext";
import MarkdownRenderer from "../MarkdownRenderer";

const MPPerkProgressMilestone = () => {
  const {
    perksState: { perks },
  } = useContext(PerksContext);

  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);
  const { milesProgressMessage } =
    contentState?.Content[3]?.perksLanding?.milestoneSummary?.currentMilestoneDetails || {};
  const [upcomingMilestones, setUpcomingMilestones] = useState<any>();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const getUpcomingMilestones = () => {
    let upcomingMilestones = perks.allMilestonePerks.filter(
      (milestone) => milestone.milestoneRequiredMiles > accountState.MileagePlanDetails?.asoaMiles
    );
    return {
      nextMilestoneId: upcomingMilestones[0]?.milestoneId,
      nextMilestoneMile: upcomingMilestones[0]?.milestoneRequiredMiles || 0,
      futureMilestoneMile: upcomingMilestones[1]?.milestoneRequiredMiles || 0,
    };
  };

  let currentMilestoneMile = Number(accountState?.MileagePlanDetails?.asoaMiles);
  let nextMilestoneId = upcomingMilestones?.nextMilestoneId;
  let nextMilestone = upcomingMilestones?.nextMilestoneMile;
  let futureMilestone = upcomingMilestones?.futureMilestoneMile;

  let toNextMilestoneMessage =
    contentState?.Content[5]?.milestones?.find((milestone) => nextMilestoneId && milestone.id === nextMilestoneId)
      ?.milestoneTrackerMessage ?? "";
  if (futureMilestone === 0 || futureMilestone === null) futureMilestone = 250000;

  if (currentMilestoneMile >= nextMilestone && nextMilestone <= futureMilestone) {
    nextMilestone = futureMilestone;
  }

  if (currentMilestoneMile >= futureMilestone) {
    currentMilestoneMile = 250000;
  }

  const currentPercentageStatus = (Number(currentMilestoneMile) / nextMilestone) * 100;

  // Calculate the progress percentage based on the current progress and final step
  let progressPercentage = (currentMilestoneMile / nextMilestone) * 98;

  if (nextMilestone >= currentMilestoneMile && nextMilestone !== futureMilestone) {
    progressPercentage *= nextMilestone / futureMilestone;
  }

  // Define the SVG width in pixels (100% width in parent container)
  const svgWidth = 100; // Adjust this if the parent container has a different width

  // Calculate the positions of the upcoming steps
  const lastStepXPosPx = svgWidth; // 30px away from the end of the line
  const lastStepXPosPercentage = (lastStepXPosPx / svgWidth) * 98;

  // Calculate the center position for the next step
  const nextStepXPosPercentage = currentMilestoneMile > nextMilestone ? 98 : (nextMilestone / futureMilestone) * 98;

  // Function to format labels as "10k", "20k", etc.
  const formatLabel = (value: number | undefined) => {
    return value ? `${value / 1000}k` : "";
  };

  const roundToOneDecimal = (num: number) => {
    // If the number is an integer, return it as is
    if (Number.isInteger(num)) {
      return num;
    }
    // Subtract a tiny amount to avoid rounding up
    return Math.floor((num - 0.05) * 10) / 10;
  };

  useEffect(() => {
    setUpcomingMilestones(getUpcomingMilestones());
  }, [perks, contentState, accountState.MileagePlanDetails]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="visual-milestone-container">
      {upcomingMilestones && (
        <>
          <div className="perks-percentage-status-value" data-testid="perks-percentage-status-value">
            {Math.floor(currentPercentageStatus) || 0}
            <span>%</span>
          </div>
          <div className="perks-percentage-status-text" data-testid="perks-percentage-status-text">
            {toNextMilestoneMessage}
          </div>
          <svg width="100%" height="50">
            <g transform={`translate(5, 0)`}>
              {/* Background line */}
              <line
                x1="2"
                y1="25"
                x2={nextMilestone > 100000 ? "93%" : "95%"}
                y2="25"
                stroke="var(--Blue-blue-background-800, #03314F)"
                strokeWidth="2"
                strokeLinecap="round" // Adds rounded edges to the background line
              />
              {/* Progress line */}
              <line
                x1={5}
                y1="25"
                x2={`${
                  currentMilestoneMile <= 8000
                    ? roundToOneDecimal(progressPercentage + (isMobile ? 2 : 1))
                    : roundToOneDecimal(progressPercentage - (nextMilestone > 100000 ? 6 : 5))
                }%`}
                y2="25"
                stroke="var(--ds-color-background-primary-100-inverse, #005E86)"
                strokeWidth="10"
                strokeLinecap="round" // Adds rounded edges to the progress line
              />

              {/* Render the next step and the final step */}

              {[nextMilestone, futureMilestone].map((step, index) => {
                const labelValue = step;
                const label = formatLabel(labelValue);
                // Determine the position for each step
                let xPos;
                if (step === nextMilestone && step !== futureMilestone) {
                  xPos = nextStepXPosPercentage;
                } else {
                  xPos = lastStepXPosPercentage;
                }

                // Skip rendering the line and label for steps that are already passed
                if (
                  (step === nextMilestone && xPos <= progressPercentage) ||
                  (step === futureMilestone && xPos === progressPercentage)
                )
                  return null;

                return (
                  <React.Fragment key={index}>
                    {/* Step line */}
                    <line
                      x1={`${xPos - (nextMilestone > 100000 ? 5 : 3)}%`}
                      y1="20"
                      x2={`${xPos - (nextMilestone > 100000 ? 5 : 3)}%`}
                      y2="30"
                      stroke="#03314F"
                      strokeWidth="4"
                      strokeLinecap="round" // Adds rounded edges to the step lines
                    />
                    {/* Step label */}
                    {xPos >= progressPercentage && (
                      <text
                        data-testid={`milestone-${index}`}
                        x={`${xPos - (nextMilestone > 100000 ? 5 : 3)}%`}
                        y="45"
                        fontSize="12"
                        textAnchor="middle"
                        fill="var(--ds-color-text-secondary-default, #2E2E2E)"
                      >
                        {label}
                      </text>
                    )}
                  </React.Fragment>
                );
              })}
            </g>
          </svg>
          <div className="progress-miles-status-text">
            <MarkdownRenderer
              markdown={replacePlaceholderName(milesProgressMessage || "", {
                m: formatNumber(currentMilestoneMile) ?? "0",
                tm: formatNumber(nextMilestone) ?? "0",
              })}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default MPPerkProgressMilestone;
