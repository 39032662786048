import React, {useContext, useEffect, useState} from 'react'

import Nav from "../../MPPerksNavigation";
import {ACTIONS, PerksContext} from '../../../context/PerksContext';
import {ContentStateType} from '../../../state/InitialState';
import {ContentContext} from "../../../context/ContentContext";
import RoundedButton from '../../RoundedButton/RoundedButton';
import cxs from 'cxs';
import "./index.scss";
import {PerksScreens, TrialPerks} from '../../../helpers/constants';
import {IFulfillmentPerkDetails, PerkFulfillmentState} from '../../../types/perks';
import UserAccountContext from '../../../context/UserAccountContext';
import ContentViewer from "../../ContentViewer";

const MPPerksRedemptionInfo = () => {
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { perksState, perksDispatch } = useContext(PerksContext);
  const { perks, perkFulfillmentStates, activeRedemption, activeScreen } = perksState;
  const currentPerkId = activeRedemption?.perkBenefitId;
  
  
  const fulfillmentDetails : IFulfillmentPerkDetails | undefined = perkFulfillmentStates.find(fulfillmentPerk => fulfillmentPerk.perkBenefitId === currentPerkId && fulfillmentPerk.fulfillmentDate === activeRedemption?.fulfillmentDate);
  const currentBenefit = perksState.perks.pastPerksList.find(perk => perk.perkBenefitId == perksState.activeRedemption!.perkBenefitId
      && perk.fulfillmentDate == perksState.activeRedemption!.fulfillmentDate );
  
  const perksArray = contentState?.Content[8]?.perksRedemptionPage?.perks;
  const contentFromContentStack  = perksArray?.find(perk => perk.perkId === currentBenefit?.perkBenefitId.toString());

  type TrialDateRange = {
    startDate :string,
    endDate : string,
    fulfillmentExpirationDate : string
  }
  const [dateRange, setDateRange] = useState<TrialDateRange>();

  const {
    background,
    buttonText,
    buttonTextColor,
    buttonBackgroundColor,
  }  = contentState?.Content[8]?.perksRedemptionPage || {};



  const perkId = fulfillmentDetails?.perkBenefitId;

  const contentPerks: any =  contentState?.Content[4]?.perks?.find(
      (perk) => perk.id === perkId
  ) || {};

  const styles= {
    nav: cxs({
      backgroundColor: `var(--${buttonBackgroundColor}, #03314f)`,
    }),
    container: cxs({
      backgroundColor: `var(--${background}, #f2fcfa)`,
    }),
    backButton: cxs({
      color: `var(--${buttonTextColor}, #FFFFFF)`,
      backgroundColor: `var(--${buttonBackgroundColor}, #03314f)`,
    }),
  }
  
  
  useEffect(() => {
    if (fulfillmentDetails?.fulfillmentDate)
    {
      const trialStartDate =  new Date(fulfillmentDetails?.fulfillmentDate).toLocaleDateString();
      const trialEndDate = currentBenefit?.benefitExpirationDate != undefined ? new Date(currentBenefit?.benefitExpirationDate).toLocaleDateString() : "";
      
      const selectionDate = currentBenefit?.selectionDate
      if(!selectionDate) return;
      const selectionDateObj = new Date(selectionDate);
      const expiration = new Date (selectionDateObj.getFullYear()+1, selectionDateObj.getMonth(), selectionDateObj.getDate()).toLocaleDateString();
      
      setDateRange({startDate: trialStartDate, endDate: trialEndDate,  fulfillmentExpirationDate: expiration});
    }
  }, []);
  
  
   const infoDisplay = () => {
     if (fulfillmentDetails?.fulfillmentState == PerkFulfillmentState.ExpiredBeforeRedemption)
       return <div>{dateRange?.fulfillmentExpirationDate}</div>
     
     const map = contentFromContentStack?.redemptionConfirmationPage?.reviewFields.map((value) => {
       if (value.name == "startDate") {
          return (
              <div>
                <b>{value.displayName}</b>
                {" " + dateRange?.startDate}
              </div>
          )
        }
        if (value.name == "endDate" && dateRange?.endDate) {
          
          return (
              <div>
            <b>{value.displayName}</b>
            {" " + dateRange?.endDate}
          </div>
          )
        }
       if (value.name == "MPNumber") {
         return (
             <div>
           <b>{value.displayName}</b>
           {" " + currentBenefit?.perksFulfillmentKey}
         </div>
         )
       }
     })
     return map;
   }

  const handleGoToHub = async () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: {screenName: PerksScreens.Hub },
    });
  };
  
  return (
  <div className={`perksRedemptionInfo ${styles.container}`} data-testid="perks-redemption-info-container">
    <Nav 
      title={contentPerks?.perkSummary} 
      returnToScreen={PerksScreens.Hub}
      customBackgroundColor={`var(--${buttonBackgroundColor}, #03314F)`}
    />
    <div className='container'>
      <h5>{activeScreen?.title}</h5>
      <p>
      {infoDisplay()}
      </p>
      <ContentViewer content={activeScreen?.data} />
    </div>
    <div className='perks_ladder_back_button'>
      <RoundedButton
          label={'Back'}
          className={styles.backButton}
          onClick={handleGoToHub}
          type="button"
          testId="ladder-back-button"
        />
    </div>
  </div>
  )
}

export default MPPerksRedemptionInfo