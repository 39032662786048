import "./style.scss";
import { useRef, forwardRef } from "react";
import MarkdownRenderer from "../MarkdownRenderer";

type ContentViewerProps = {
  content: string | TrustedHTML;
  isPage?: boolean;
  markdownClassName?: string;
  ref?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
};

const ContentViewer = forwardRef<HTMLDivElement, ContentViewerProps>((props, ref) => {
  const { content, isPage = true, markdownClassName = "", children } = props;

  // Create a default ref if no ref is passed as a prop
  const defaultRef = useRef<HTMLDivElement>(null);
  const contentViewerRef = ref || defaultRef; // Use passed ref or default one

  return (
    <article className={isPage ? "content-container container" : ""}>
      <section ref={contentViewerRef} className="content-data">
        <MarkdownRenderer markdownClassName={markdownClassName} markdown={content} />
        {children}
      </section>
    </article>
  );
});

export default ContentViewer;
