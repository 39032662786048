import "./index.scss";
import { useContext, useEffect } from "react";
import MPPerkList from "../MPPastPerks/MPPerkList";
import SelectPerksModule from "../MPSelectPerks/MPSelectPerksContainer";
import MPMilestoneContainer from "../MPMilestoneContainer";
import Nav from "../../MPPerksNavigation";
import { ContentStateType } from "../../../state/InitialState";
import { ContentContext } from "../../../context/ContentContext";
import { ACTIONS, PerksContext } from "../../../context/PerksContext";
import UserAccountContext from "../../../context/UserAccountContext";
import { OptimizelyFeature } from "@optimizely/react-sdk";
import MPArrivingSoon from "./MPArrivingSoon";
import Spinner from "../../Spinner";
import { FEATURE_FLAGS } from "../../../context/FeatureContext";

const MPPerksContainer = () => {
  const {
    accountState: { isLoadingToken },
  } = useContext(UserAccountContext);
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);

  const {
    perksDispatch,
    perksState: { isLoadingPerkData, perks },
  } = useContext(PerksContext);
  const isLoadingMobileContent = contentState.isLoadingMobileContent;

  const { title } = contentState?.Content[3]?.perksLanding?.topNav || {};

  const isTestEnv = process.env.REACT_APP_RELEASE_ENV === "test" || process.env.REACT_APP_RELEASE_ENV === "dev";

  useEffect(() => {
    perksDispatch({ type: ACTIONS.SET_SELECTED_PERK_BENEFITS, value: [] });
    perksDispatch({ type: ACTIONS.SET_SELECTED_MILESTONE_PERK, value: null });
  }, []);

  return (
    <div className="overviewPerksContainer">
      <Nav title={title ?? "Perks"} webTitle="Mileage Plan™ journey" returnToScreen="hub">
        <auro-breadcrumb href="https://www.alaskaair.com">Home</auro-breadcrumb>
        <auro-breadcrumb href="https://www.alaskaair.com/account/overview">Account</auro-breadcrumb>
        <auro-breadcrumb>Mileage Plan journey</auro-breadcrumb>
      </Nav>
      <div className="mPPerksDetails">
        {(isLoadingPerkData || isLoadingMobileContent || isLoadingToken) && (
          <div className="perks-loader-container">
            <Spinner />
          </div>
        )}
        <MPMilestoneContainer />
        {perks?.availableMilestonePerks?.length > 0 || perks?.pastPerksList?.length > 0 ? (
          <OptimizelyFeature feature={FEATURE_FLAGS.PERK_SELECTION_TOGGLE}>
            {(isEnabled, variables) =>
              (isEnabled && variables.openToAll) || isTestEnv ? (
                <div className="mPSelectedPerks">
                  <SelectPerksModule />
                  <MPPerkList />
                </div>
              ) : null
            }
          </OptimizelyFeature>
        ) : null}
        <MPArrivingSoon />
      </div>
    </div>
  );
};

export default MPPerksContainer;
