import { createContext, Dispatch, FC, ReactNode, useContext, useEffect, useReducer } from "react";
import {
  IPerksResponse,
  IPerkBenefit,
  IMilestonePerk,
  IGetPerksRequest,
  IActiveScreen,
  IFulfillmentPerkDetails,
  IActiveRedemption,
} from "../types/perks";
import UserAccountContext from "./UserAccountContext";
import { initialStateType, initialState } from "../state/perksState";
import fetchPerksData from "../helpers/fetchPerksData";

export type PerksContextType = {
  perksState: initialStateType;
  perksDispatch: Dispatch<ActionType>;
  getPerksData: () => void;
};

export const PerksContext = createContext<PerksContextType>({
  perksState: initialState,
  perksDispatch: () => { },
  getPerksData: () => { },
});

export const ACTIONS = {
  SET_ACTIVE_SCREEN: "SET_ACTIVE_SCREEN",
  SET_SELECTED_MILESTONE_PERK: "SET_SELECTED_MILESTONE_PERK",
  SET_SELECTED_PERK_BENEFITS: "SET_SELECTED_PERK_BENEFITS",
  SET_PERKS: "SET_PERKS",
  SET_REDEMPTION_STATE: "SET_REDEMPTION_STATE",
  SET_ACTIVE_REDEMPTION: "SET_ACTIVE_REDEMPTION",
  SET_LOADING_DATA: "SET_LOADING_DATA",
  REFRESH: "REFRESH",
};

type ActionType = {
  type: string;
  value:
  | IActiveRedemption
  | IPerkBenefit[]
  | IPerksResponse
  | IMilestonePerk
  | IFulfillmentPerkDetails[]
  | IActiveScreen
  | null
  | boolean;
};

const perksReducer = (state: initialStateType, action: ActionType): initialStateType => {
  switch (action.type) {
    case ACTIONS.SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action.value as IActiveScreen,
      };
    case ACTIONS.SET_SELECTED_MILESTONE_PERK:
      return {
        ...state,
        selectedMilestonePerk: action.value as IMilestonePerk,
      };
    case ACTIONS.SET_SELECTED_PERK_BENEFITS:
      return {
        ...state,
        selectedPerkBenefits: action.value as IPerkBenefit[],
      };
    case ACTIONS.SET_PERKS:
      return {
        ...state,
        perks: action.value as IPerksResponse,
      };
    case ACTIONS.SET_REDEMPTION_STATE:
      return {
        ...state,
        perkFulfillmentStates: action.value as IFulfillmentPerkDetails[],
      };
    case ACTIONS.SET_ACTIVE_REDEMPTION:
      return {
        ...state,
        activeRedemption: action.value as IActiveRedemption,
      };
    case ACTIONS.SET_LOADING_DATA:
      return {
        ...state,
        isLoadingPerkData: action.value as boolean,
      };
    default:
      return state;
  }
};

export const PerksProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { accountState } = useContext(UserAccountContext);
  const [perksState, perksDispatch] = useReducer(perksReducer, initialState);
  
  const getPerksData = async () => {
    const payload: IGetPerksRequest = {
      asoaMiles: 0,
      guid: accountState.Token.Guid,
      mileagePlanNumber: accountState.Token.MileagePlanNumber,
    };
    await fetchPerksData(payload, accountState.Token.Value, perksDispatch);
  };

  useEffect(() => {
    perksDispatch({ type: ACTIONS.SET_LOADING_DATA, value: true });    
    getPerksData();
  }, [accountState.Token]);

  const contextValue = {
    perksDispatch,
    getPerksData,
    perksState,
  };

  return <PerksContext.Provider value={contextValue}>{children}</PerksContext.Provider>;
};
