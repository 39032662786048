import { useContext } from "react";
import cxs from "cxs";
import PerkIcon, { PerkIconProps } from "../../MPPerkIcon";
import { ContentContext } from "../../../context/ContentContext";
import { ContentStateType } from "../../../state/InitialState";
import "./styles.scss";
import InfoIcon from "../../InfoIcon";
import { IPerkBenefit } from "../../../types/perks";

type PerksTileProps = PerkIconProps & {
  description: string;
  perkSelectionTileSubtitle: string;
  selected: boolean;
  handleClick: (id: number) => void;
  id: number;
  isButton: boolean;
  type?: "button";
  testid?: string;
  selectedPerks: IPerkBenefit[];
};

const MPPerksTile = (props: PerksTileProps) => {
  const { description, perkSelectionTileSubtitle, icon, category, selected, handleClick, id, isButton, testId } = props;

  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);

  const {
    titleColor: selectedTitleColor,
    subtitleColor: selectedSubtitleColor,
    borderColor: selectedBorderColor,
    iconBackgroundColor: selectedIconBackgroundColor,
    iconBorderColor: selectedIconBorderColor,
    iconColor: selectedIconColor,
  } = contentState?.Content[1]?.perkSelection?.perk?.perkSelected || {};

  const {
    titleColor: UnselectedTitleColor,
    iconBackgroundColor: UnselectedIconBackgroundColor,
    subtitleColor: UnselectedSubTitleColor,
    borderColor: UnselectedBorderColor,
    iconBorderColor: UnselectedIconBorderColor,
    iconColor: UnselectedIconColor,
  } = contentState?.Content[1]?.perkSelection?.perk?.perkUnselected || {};

  const styles = {
    title: cxs({
      color: selected ? `var(--${selectedTitleColor}, #2E2E2E)` : `var(--${UnselectedTitleColor}, #2E2E2E)`,
    }),
    subtitle: cxs({
      color: selected ? `var(--${selectedSubtitleColor}, #151515)` : `var(--${UnselectedSubTitleColor}, #151515)`,
    }),
    button: cxs({
      backgroundColor: selected ? "white" : "transparent",
      borderColor: selected ? `var(--${selectedBorderColor}, #D4D4D4)` : `var(--${UnselectedBorderColor}, #D4D4D4)`,
    }),
    iconContainer: cxs({
      color: selected ? `var(--${selectedIconColor}, #03314F)` : `var(--${UnselectedIconColor}, #03314F)`,
      borderColor: selected
        ? `var(--${selectedIconBorderColor}, #03314F)`
        : `var(--${UnselectedIconBorderColor}, #03314F)`,
      backgroundColor: selected ? `var(--${selectedIconBackgroundColor}, #00446A)` : `${UnselectedIconBackgroundColor}`,
    }),
  };

  const handleTileClick = () => handleClick(id);

  const getButtonClassName = (isButton: boolean) => {
    let className = "perksTile__buttonContainer ";
    if (isButton) {
      className += `buttonType ${styles.button}`;
    }
    return className;
  };

  const buttonClass = getButtonClassName(isButton);

  return (
    <div className="perksTile__container">
      <button className={buttonClass} onClick={handleTileClick} data-testid={testId}>
        <PerkIcon
          icon={icon}
          category={category}
          variant={!selected ? "outline" : "solid"}
          iconContainerClassName={styles.iconContainer}
        />
        <div className="perksTile__textContainer">
          <div className={`perksTile__name ${styles.title}`} dangerouslySetInnerHTML={{ __html: description }}></div>
          <div className={`perksTile__date ${styles.subtitle}`}>
            {perkSelectionTileSubtitle || ""}
          </div>
        </div>
      </button>

      {isButton && <InfoIcon selectedPerks={props.selectedPerks} perkId={id} />}
    </div>
  );
};

export default MPPerksTile;
