import { useDecision } from '@optimizely/react-sdk';
export const FEATURE_FLAGS = {
  PERKS: 'perks',
  HUNDRED_K_CHOICE:'100k_choice',
  PERK_SELECTION_TOGGLE: 'perkselectiontoggle',
};

export const useFeatureDecision = () => {
  const [decision] = useDecision(FEATURE_FLAGS.PERKS, {
    autoUpdate: true,
  });

  return {
    isMilestoneEnabled: (milestoneId: string) => decision.variables[(decision.variables.variableMap as any)?.milestoneFlags[milestoneId]] ?? false,
    isPerkEnabled: (perkName: string) => decision.variables[perkName] ?? decision.variables[(decision.variables.variableMap as any)?.perkFlags[perkName]] ?? false,
  };
}
